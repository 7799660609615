<template>
  <div>
    <div
      class="margin-left: 150px;margin-top: 50px;margin-right: 150px;background-color: #D9661F;"
    >
      <div class="lisbgimgview">
        <img
          src="../../../assets/newListBg.png"
          style="width: 100%; height: 100%; object-fit: fill"
        />
      </div>

      <div class="my-events">
        <div
          v-for="(item, index) in my_events"
          :key="index"
          style="margin-top: 20px"
        >
          <div class="events-item">
            <div style="position: absolute; z-index: 1; width: 100%">
              <img
                src="../../../assets/event_bg.png"
                style="width: 100%; height: 140px; margin-top: 10px"
              />
            </div>
            <div style="z-index: 2; padding: 0px 36px">
              <div class="event_name">
                {{ item.event_name }}
              </div>
              <div
                style="
                  position: relative;
                  margin-top: 10px;
                  height: 30px;
                  display: flex;
                  flex-direction: row;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      font-family: Montserrat-SemiBold;
                      color: #e5ac11;
                      font-size: 15px;
                      font-weight: 600;
                    "
                  >
                    Entry Time
                  </div>
                  <div
                    style="
                      font-family: Montserrat-SemiBold;
                      color: #e5ac11;
                      font-size: 15px;
                      font-weight: 600;
                      margin-left: 10px;
                    "
                  >
                    {{ formatSessionDate(item.event_start_time) }}
                  </div>

                  <div
                    style="
                      margin-left: 50px;
                      font-family: Montserrat-SemiBold;
                      color: #e5ac11;
                      font-size: 15px;
                      font-weight: 600;
                    "
                  >
                    Start Time
                  </div>
                  <div
                    style="
                      font-family: Montserrat-SemiBold;
                      color: #e5ac11;
                      font-size: 15px;
                      font-weight: 600;
                      margin-left: 10px;
                    "
                  >
                    {{ formatSessionDate(item.event_start_time) }}
                  </div>
                </div>
                <div class="buttonStyle">
                  <!-- <div class="event-status">
                    {{ status_en_labels[item.status.status] }}
                  </div> -->
                  <div class="event-entry" @click="goScreen(item)">
                    {{ "&nbsp;&nbsp;Enter&nbsp;" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getZhiboRooms } from "../../../api/index";
import { getProjectCode } from "../../../utils/store";
import { formatDate } from "../../../utils/date";
import "../../../assets/common/font.css";
export default {
  name: "SessionList",
  data() {
    return {
      my_events: [],
      student_name: "",
      student_school: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getZhiboRooms(getProjectCode()).then((res) => {
        this.my_events = res.data.data.event_rooms;
      });
    },
    showScore() {
      this.$router.push({
        path: "/award",
        query: {},
      });
    },
    formatSessionDate(time) {
      var time_1 = JSON.parse(time) * 1000;
      var date = new Date(time_1);
      return formatDate(date, "yyyy-MM-dd hh:mm");
    },
    goScreen(data) {
      let url = this.$router.resolve({
        path: "/employeescreen",
        query: {
          roomId: data.event_meeting_room_ids[0],
        },
      });
      window.open(url.href, "_blank");
    },
  },
};
</script>

<style scoped>
.buttonStyle {
  /* top: -20%; */
  transform: translateX(-30%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  margin-top: -46px;
  /* height: 80px; */
  /* background-color: #D9661F; */
}
.event-entry {
  /* font-family: Montserrat-SemiBold; */
  cursor: pointer;
  padding: 2px 5px;
  /* width: 50px; */
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  background: #e5ac11;
  /* text-align: center; */
  margin-top: 15px;
  /* border-radius: 5px; */
  /* border: 2px solid #6F69F7; */
  font-family: Montserrat-SemiBold;
}

.event-status {
  /* margin-top: px; */
  font-weight: 600;
  font-size: 18px;
  padding: 2px 5px;
  color: #218393;
  background: white;
  /* border-radius: 5px; */
  border: 1px solid #218393;
  font-family: Montserrat-SemiBold;
}

.event_name {
  font-family: Montserrat-SemiBold;
  font-size: 36px;
  font-weight: 600;
  color: #218393;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.events-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 80px;
}

.my-events {
  margin: 20px auto;
  width: 80%;
  /* margin-right: 150px; */
}

.student-session-title {
  font-weight: 600;
  color: #c4d7ff;
  font-size: 18px;
  font-family: Montserrat-SemiBold;
}

.session-title-1 {
  font-family: Montserrat-SemiBold;
  font-weight: 600;
  color: white;
  font-size: 36px;
}

.session-name {
  font-size: 28px;
  font-weight: 600;
  color: white;
  margin: 10px 0 0 45px;
  font-family: Montserrat-SemiBold;
}

.session-item {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 340px;
  height: 285px;
  background-image: url("../../../assets/session_nav_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.session-item-bottom {
  position: relative;
  height: 110px;
  margin-top: 30px;
  padding-left: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.session-time {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #f18601;
  font-weight: 600;
  font-family: Montserrat-SemiBold;
}

.sessionList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.type_label {
  font-size: 18px;
  margin-left: 10px;
}

.topBanner1 {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-repeat: no-repeat;
  background-color: #d7ecee;
  background-size: cover;
  color: #218393;
}
.bottomView {
  position: relative;
  /* z-index: 1; */
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-repeat: no-repeat;
  background-color: #d9661f;
  background-size: cover;
}
.lisbgimgview {
  position: absolute;
  z-index: 2;
  width: 265.25px;
  height: 220.5px;
  bottom: 0px;
}
.countryStyle {
  position: absolute;
  margin-top: -20px;
  margin-left: 150px;
  font-size: 20px;
  color: #218393;
  height: 25px;
  font-family: Montserrat-SemiBold;
}

.nameStyle {
  position: absolute;
  margin-top: 25px;
  margin-left: 150px;
  font-size: 13px;
  color: #218393;
  height: 25px;
  font-family: Montserrat-SemiBold;
}
.showScoreStyle {
  position: absolute;
  right: 200px;
  /* margin-right: 150px; */
  /* margin-top: 25px; */
  /* margin-left: 150px; */
  font-size: 13px;
  width: 120px;
  color: #218393;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border: 1px solid #218393;
  font-family: Montserrat-SemiBold;
}
</style>
